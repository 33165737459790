<template>
    <div>
        <v-overlay
                :value="loader"
                absolute
                opacity="0.15"
        >
            <v-row>
                <v-col class="text-center">
                    <v-progress-circular
                        color="primary"
                        indeterminate
                        size="40"
                        width="6"
                    />
                </v-col>
            </v-row>
        </v-overlay>
        <v-data-table
            :headers="headers"
            :items="items"
            :no-data-text="$t('message.noDocumentHistoryHere')"
            class="appic-table-light specification-table"
            dense
            item-key="Audit.id"
        >
            <template v-slot:top>
                <div class="flex flex-row align-center justify-space-between">
                    <span class="font-italic font-xs mt-2 mb-0">{{ $t('message.noteDocumentHistory') }}</span>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on: tooltip }">
                            <v-btn icon @click="reloadHistory()" v-on="{ ...tooltip }" :loading="loading">
                                <v-icon>refresh</v-icon>
                            </v-btn>
                        </template>
                        <span>{{ $t('message.reload') }}</span>
                    </v-tooltip>
                </div>
            </template>
            <template v-slot:item.Audit.event="{ item }">
                {{ replaceText(item.Audit.event) }}
            </template>
        </v-data-table>
    </div>
</template>

<script>
    import {formatDate} from "Helpers/helpers";
    import {api} from "../../api";

    export default {
        name: "DocumentHistory",
        props: ['asset','asset_id','textReplace'],
        data() {
            return {
                items: [],
                loader: false,
                loading: false,
            }
        },
        computed: {
            formatDate: () => formatDate,
            headers (){
                let headers = [
                    {
                        id: 0,
                        text: this.$t('message.eventDate') + ' GMT+0',
                        value: 'Audit.created_at',
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                        searchable: false,
                        sortable: false
                    },
                    {
                        id: 1,
                        text: this.$t('message.event'),
                        value: 'Audit.event',
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                        searchable: false,
                        sortable: false
                    },
                    {
                        id: 2,
                        text: this.$t('message.user'),
                        value: 'User.title',
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                        searchable: false,
                        sortable: false
                    },
                    {
                        id: 3,
                        text: this.$t('message.ipAddress'),
                        value: 'Audit.ip_address',
                        class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                        searchable: false,
                        sortable: false
                    }
                ]
                return headers
            }
        },
        methods: {
            replaceText (text) {
                if(this.textReplace?.length > 0){
                    this.textReplace.forEach(obj => {
                        Object.entries(obj).forEach(entry => {
                            const [key, value] = entry
                            text = text.replace(key,value)
                        })
                    })
                    return text
                } else {
                    return text
                }
            },
            loadHistory() {
                this.loader = true
                this.loading = true
                api
                    .get("/audits/" + this.asset + "/" + this.asset_id)
                    .then(response => {
                        if(response.data.status == 'success'){
                            this.items = response.data.data
                        } else {
                            this.$toast.error(this.$t('message.errors.cannotLoadHistory'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                        }
                        this.loader = false
                        this.loading = false
                    })
                    .catch(() => {
                        this.$toast.error(this.$t('message.errors.cannotLoadHistory'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                        this.loader = false
                        this.loading = false
                    })
            },
            reloadHistory() {
                this.loadHistory()
            }
        },
        created(){
            this.loadHistory()
        }
    }
</script>

<style>
.text-start {
    padding-left: 3px !important;
    padding-top: 3px !important;
    padding-right: 3px !important;
    vertical-align: middle !important;
}
.v-data-table td {
    padding: 0 3px;
    font-size: 0.8rem !important;
    line-height: 0.8rem;
}
</style>